/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import ReactSelectDropdown from '+containers/Dashboard/Shared/ReactSelectDropdown';
import LienForm from '+dashboard/Merchants/MerchantDetails/components/Balances/components/LienForm';
import { useFeedbackHandler, useReducerState } from '+hooks';
import APIRequest from '+services/api-services';
import { Storage as StorageService } from '+services/storage-services';
import { CurrencyType, ICreateLienData, ILienModalProps, LienStageType, UpdateLienDataType } from '+types';
import { backwardAmountInput, capitalize, cleanInput, formatAmount, formatWithCommas, logError, storageDataKey } from '+utils';

const api = new APIRequest();

const LienModal = ({
  accountId,
  merchantName,
  lienStage,
  setLienStage,
  amount,
  lienReference,
  currency,
  paymentReference,
  balance
}: ILienModalProps) => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useReducerState({
    merchant: merchantName ?? '',
    currency: currency ?? '',
    amount: amount ?? '',
    reason: ''
  });
  const availableCurrencies = (StorageService.getItem(storageDataKey.AVAILABLE_CURRENCIES) ?? []) as string[];

  const createLien = useMutation((value: ICreateLienData) => api.createLien(value), {
    onSuccess: () => {
      queryClient.invalidateQueries([`LIEN_HISTORY`, `${id}`]);
      queryClient.invalidateQueries([`LIEN_SUMMARY`, `${id}`]);
      queryClient.invalidateQueries(['MERCHANT_BALANCES', id]);
      queryClient.invalidateQueries([`LIEN_HISTORY`]);
      queryClient.invalidateQueries(['RELATED_TRANSACTIONS', `${paymentReference}`]);
      queryClient.invalidateQueries([`PAYIN_DETAILS_${paymentReference}`]);
      queryClient.invalidateQueries(['DISPUTES_LIST', `${paymentReference}`]);
      setState({ amount: '' });
    },
    onError: (error: { response: { data: { data: { amount: { message: string } }; message: string } } }) => {
      logError(error);

      feedbackInit({
        message: capitalize(
          `${error?.response?.data?.data?.amount.message ?? error?.response?.data?.message}` ||
            "There has been an error creating lien on this merchant's balance"
        ),
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const updateLien = useMutation((value: UpdateLienDataType) => api.updateLien(lienReference as string, value), {
    onSuccess: () => {
      queryClient.invalidateQueries([`LIEN_HISTORY`, `${id}`]);
      queryClient.invalidateQueries([`LIEN_SUMMARY`, `${id}`]);
      queryClient.invalidateQueries(['MERCHANT_BALANCES', id]);
      queryClient.invalidateQueries([`LIEN_HISTORY`]);
      queryClient.invalidateQueries(['RELATED_TRANSACTIONS', `${paymentReference}`]);
      queryClient.invalidateQueries([`TRANSACTION_DETAILS_${paymentReference}`]);
      queryClient.invalidateQueries(['DISPUTES_LIST', 'refunds']);
    },
    onError: (error: { response: { data: { data: { amount: { message: string } }; message: string } } }) => {
      logError(error);

      feedbackInit({
        message: capitalize(
          `${error?.response?.data?.data?.amount.message ?? error?.response?.data?.message}` || 'There has been an error updating this lien'
        ),
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const handleDisplayModal = (type: LienStageType) => {
    setLienStage(type);
  };
  const handleCloseModal = () => {
    setLienStage('');
    setState({ amount: '' });
  };

  const modalActions = () => {
    if (['place', 'debit', 'release', 'confirm-lien'].includes(lienStage as keyof typeof modalDetails))
      return { close: handleCloseModal, ...modalDetails[lienStage as keyof typeof modalDetails] };
    return {};
  };

  useEffect(() => {
    if (lienStage === 'place' || !lienStage) return;
    handleDisplayModal(lienStage);
  }, [lienStage]);

  const modalDetails = {
    place: {
      heading: `Place lien on ${paymentReference ? 'transaction' : 'account'}`,
      description: `Setup a minimum balance to place a lien on this ${paymentReference ? 'transaction' : 'account'}.`,
      content: (
        <>
          <div className="form-group">
            <label htmlFor="merchant">Business Name</label>
            <input type="numeric" id="merchant" className={`form-control`} disabled value={state.merchant} />
          </div>
          <div className="form-group">
            <ReactSelectDropdown
              label="Currency"
              onChange={value => setState({ currency: value as CurrencyType })}
              placeholder="-- Select Currency --"
              options={availableCurrencies.map(currency => ({ label: currency, value: currency }))}
              value={state.currency}
              disabled={!!currency}
            />
          </div>
          {balance && balance > 0 && (
            <div className="form-group">
              <label htmlFor="balance">Available Balance</label>
              <input type="numeric" id="balance" className={`form-control`} disabled value={formatAmount(balance)} />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              type="numeric"
              id="amount"
              disabled={!!amount}
              className={'form-control'}
              placeholder="Enter amount"
              value={formatWithCommas(state.amount)}
              onChange={e => {
                const formattedAmount = backwardAmountInput(cleanInput(e.target.value.replace(/,/g, '')));
                setState({ amount: String(formattedAmount) });
              }}
            />
          </div>
        </>
      ),
      secondButtonActionIsTerminal: false,
      secondButtonDisable: state.amount === '' || +state.amount === 0 || +state.amount < 1,
      secondButtonText: 'Continue',
      size: 'mdBase',
      equalFooterBtn: true,
      headerBottomBorder: true,
      formCenter: true,
      secondButtonAction: () => {
        handleDisplayModal('confirm-lien');
      }
    },
    release: {
      heading: "Release lien on merchant's NGN account",
      description: '',
      secondButtonText: 'Release Lien',
      content: <LienForm type="release" setReason={value => setState({ reason: value })} amount={Number(amount)} currency={currency} />,
      completedHeading: 'Done',
      completedDescription: 'The lien has been released from this account.',
      size: 'mdBase',
      secondaryCompletedModal: true,
      equalFooterBtn: false,
      headerBottomBorder: true,
      formCenter: true,
      secondButtonDisable: !['all'].includes(state.reason) && state.reason === '',
      secondButtonAction: async () => {
        if (!state.reason) return;
        await updateLien.mutateAsync({
          status: 'released',
          reason: state.reason
        });
      }
    },
    debit: {
      size: 'mdBase',
      firstButtonText: 'Cancel',
      secondButtonText: 'Debit Lien',
      secondaryCompletedModal: true,
      headerBottomBorder: true,
      modalClassName: 'team-permission-modal',
      heading: "Debit lien on merchant's NGN account",
      description: '',
      completedHeading: 'Done',
      completedDescription: "The liened amount has been debited from the merchant's account",
      content: <LienForm type="debit" setReason={value => setState({ reason: value })} amount={Number(amount)} currency={currency} />,
      secondButtonDisable: !['failure', 'unsupported', 'fraud', 'all'].includes(state.reason) && state.reason === '',
      secondButtonAction: async () => {
        if (!state.reason) return;
        await updateLien.mutateAsync({
          status: 'withheld',
          reason: state.reason
        });
      }
    },
    'confirm-lien': {
      size: 'mdBase',
      firstButtonText: 'Cancel',
      secondButtonText: 'Place Lien',
      secondaryCompletedModal: true,
      headerBottomBorder: true,
      modalClassName: 'team-permission-modal',
      heading: `Place lien on merchant's NGN ${paymentReference ? 'transaction' : 'account'}`,
      description: '',
      completedHeading: 'Done',
      completedDescription: `A lien has been placed on this ${paymentReference ? 'transaction' : 'account'}.`,
      content: (
        <LienForm
          type="confirm-lien"
          setReason={value => setState({ reason: value })}
          currency={state.currency}
          amount={Number(state.amount)}
        />
      ),
      secondButtonDisable: !['failure', 'unsupported', 'fraud', 'all'].includes(state.reason) && state.reason === '',
      secondButtonAction: async () => {
        if (!state.reason && !state.amount) return;
        await createLien.mutateAsync({
          account_id: accountId !== null && accountId !== undefined ? Number(accountId) : undefined,
          currency: state.currency,
          reason: state.reason,
          amount: Number(state.amount),
          payment_source_reference: paymentReference ?? undefined
        });
      }
    }
  };

  return <Modal visible={!!lienStage} isScrollable {...(modalActions() as unknown as IModalProps)} />;
};

export default LienModal;
