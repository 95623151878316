/* eslint-disable camelcase */
import React, { useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Icon from '+containers/Dashboard/Shared/Icons';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import { useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import { Storage } from '+services/storage-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import LargeExportModal from '+shared/LargeExportModal';
import Table from '+shared/Table';
import {
  CurrencyType,
  FileFormatType,
  IMerchantBalanceSubActionTab,
  IMerchantData,
  LienDataType,
  LienStageType,
  MerchantBalanceHistoryType,
  MerchantBalanceType,
  MerchantReserveHistoryType,
  TableTypes
} from '+types';
import {
  APIDownload,
  capitalizeRemovedash,
  customTabOrder,
  filteredOutObjectProperty,
  formatAmount,
  isAllowed,
  logError,
  queriesParams,
  storageDataKey,
  swapArrayPositions,
  switchCurrency
} from '+utils';
import useStore from '+zustandStore';

import { GenerateBalanceStatementModal } from './components/GenerateBalanceStatementModal';
import LienModal from './components/LienModal';
import { switchBalanceHistory, TabType, tabType } from './helpers/balanceHistoryTableHelper';

const api = new APIRequest();

export default function BalancesTab() {
  const { feedbackInit } = useFeedbackHandler();
  const { id } = useParams<{ id: string }>();
  const [tabs, setTabs] = useState<TabType[]>([]);
  const [balances, setBalances] = useState<MerchantBalanceType>({} as MerchantBalanceType);
  const [lienStage, setLienStage] = useState<LienStageType>('');
  const [transactionDetail, setTransactionDetail] = useState<{ amount: string; reference: string }>({ amount: '', reference: '' });
  const [showGenerateBalanceModal, setShowGenerateBalanceModal] = useState<boolean>(false);

  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const { profile } = useStore(state => state);
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const currentPage = searchQuery.value.page ?? 1;
  const limit = searchQuery.value.limit ?? 25;
  const activeTab = (searchQuery.value.subTab ?? tabType.balance) as TabType;
  const currency = searchQuery.value.currency ?? ('NGN' as CurrencyType);
  const lienReference = searchQuery.value.keyword;
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.tab,
      queriesParams.limit,
      queriesParams.subTab,
      queriesParams.page,
      queriesParams.totalItems,
      queriesParams.keyword
    ])
  };

  const merchantDetails = Storage.getItem(storageDataKey.SINGLE_MERCHANT) as unknown as IMerchantData;
  const showLien = Boolean(['NGN'].includes(currency) && isAllowed(userAccess, ['lien.view']));
  const availableBalance = balances[currency as keyof typeof balances]?.available_balance;

  useLayoutEffect(() => {
    if (isAllowed(userAccess, ['merchant_balance_history.view'])) {
      setTabs(prev => [...prev, 'Balance']);
    }
    if (isAllowed(userAccess, ['merchant_rolling_reserve_balance_history.view'])) {
      setTabs(prev => [...prev, 'Reserve']);
    }
    if (showLien) {
      setTabs(prev => [...prev, 'Lien']);
    } else {
      setTabs(prev => [...prev.filter(tab => tab !== 'Lien')]);
      searchQuery.setQuery({ subTab: 'Balance' });
    }
  }, [userAccess, showLien]);

  const {
    data: balancesData,
    refetch: refetchBalance,
    isLoading: balanceIsLoading
  } = useQuery(['MERCHANT_BALANCES', id], () => api.getBalances(id), {
    refetchOnMount: 'always',
    onSuccess: data => setBalances(data?.data || {}),
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this merchant's balances `,
        type: 'danger',
        action: {
          action: () => refetchBalance(),
          name: 'Try again'
        }
      });
    }
  });

  const {
    data: reserveData,
    refetch: refetchReserve,
    isFetching: isFetchingReserveData
  } = useQuery(
    ['ROLLING_RESERVE', currency, currentPage, limit, id],
    () => api.getRollingReserveHistory(id, currency, Number(currentPage), Number(limit)),
    {
      enabled: balances[currency as keyof typeof balances]?.hasOwnProperty('reserve_balance') || false,
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error in getting this merchant's ${currency} rolling reserve `,
          type: 'danger',
          action: {
            action: () => refetchReserve(),
            name: 'Try again'
          }
        });
      }
    }
  );
  const {
    data: merchantBalanceData,
    refetch: refetchMerchantBalanceHistory,
    isFetching: merchantBalanceHistoryLoading
  } = useQuery(
    ['MERCHANT_BALANCES_HISTORY', currency, currentPage, limit, id, sortingParams],
    () => api.getBalanceHistory(id, currency, Number(currentPage), Number(limit), sortingParams),
    {
      refetchOnMount: 'always',
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the balance history for ${currency}.`,
          type: 'danger',
          action: {
            action: () => refetchMerchantBalanceHistory(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const {
    data: LienHistoryData,
    refetch: refetchLien,
    isFetching: lienLoading
  } = useQuery(
    ['LIEN_HISTORY', id, limit, currentPage, currency, sortingParams, lienReference],
    () =>
      api.getLiens({
        merchantId: id,
        page: Number(currentPage),
        limit: Number(limit),
        params: { ...sortingParams, reference: lienReference ?? undefined }
      }),
    {
      enabled: showLien,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the lien history for ${currency}.`,
          type: 'danger',
          action: {
            action: () => refetchLien(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const {
    data: lienSummaryData,
    refetch: refetchSummaryLien,
    isFetching: lienSummaryLoading
  } = useQuery(['LIEN_SUMMARY', id], () => api.getLienSummary({ id, params: { status: 'active' } }), {
    enabled: showLien,
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the lien history for ${currency}.`,
        type: 'danger',
        action: {
          action: () => refetchSummaryLien(),
          name: 'Try again'
        }
      });
    }
  });

  const reserveBalance = reserveData?.data || [];
  const merchantBalance = merchantBalanceData?.data || [];
  const lienData = LienHistoryData || [];

  const data = {
    Balance: merchantBalanceData?.data || [],
    Reserve: reserveData?.data || [],
    Lien: lienData || []
  };

  const totalItems = {
    Balance: merchantBalance?.paging?.total_items,
    Reserve: reserveBalance?.paging?.total_items,
    Lien: lienData?.paging?.total_items
  };

  const pageSize = {
    Balance: merchantBalance?.paging?.page_size,
    Reserve: reserveBalance?.paging?.page_size,
    Lien: lienData?.paging?.page_size
  };

  const loading = {
    Balance: merchantBalanceHistoryLoading,
    Reserve: isFetchingReserveData,
    Lien: lienLoading
  };

  const currentData = data[activeTab as keyof typeof data];
  const currentTotalItems = totalItems[activeTab as keyof typeof totalItems];
  const currentPageSize = pageSize[activeTab as keyof typeof pageSize];
  const currentLoading = loading[activeTab as keyof typeof loading];

  const handleLienOption = (type: LienStageType, amount: string, reference: string) => {
    setLienStage(type);
    setTransactionDetail({ amount: String(amount), reference });
  };

  const filterExportPermission = {
    Balance: isAllowed(userAccess, ['merchant_balance_history.export']),
    Reserve: false,
    Lien: isAllowed(userAccess, ['lien.export'])
  };

  const tableType = {
    Balance: 'merchant-balance-history',
    Reserve: 'merchant-balance-history',
    Lien: 'merchant-lien-history'
  } as { [key in TabType]: TableTypes };

  const exportMerchantHistory = async (
    format: FileFormatType,
    close: () => void,
    fieldsToExport: string | string[],
    from: string,
    to: string
  ) => {
    const typeInfo = {
      Balance: 'balance history',
      Reserve: 'reserve balance history',
      Lien: 'lien history'
    };

    const handleTimeDescription = () => {
      if (!from && !to) return 'of all time';
      if (!from && to) return `up to ${to}`;
      if (from && !to) return `from ${from}`;
      return `from: ${from} to: ${to}`;
    };
    try {
      const res = await (activeTab === 'Lien'
        ? api.exportLienHistory(id, currency, format, fieldsToExport, from, to)
        : api.exportMerchantHistory(activeTab?.toLowerCase(), id, currency, format, fieldsToExport, from, to));
      const type = format === 'csv' ? 'csv' : 'xlsx';
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        APIDownload(res, `${currency} ${typeInfo[activeTab]} ${handleTimeDescription()}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: (
            <>
              {' '}
              - Successfully exported <strong>{`${activeTab.toLowerCase()} history.`}</strong>
            </>
          ),
          type: 'success'
        });
      }
      close();
    } catch (error) {
      logError(error);
      close();
      feedbackInit({
        message: `There has been an error exporting your ${switchCurrency[currency as keyof typeof switchCurrency]} ${typeInfo[activeTab]}`,
        type: 'danger'
      });
      throw error;
    }
  };

  const tableDataKeys = Object.keys(
    switchBalanceHistory(activeTab, handleLienOption, userAccess).fields(
      {} as MerchantBalanceHistoryType & MerchantReserveHistoryType & LienDataType
    ).data
  );
  const displaySubActionTabs = (): IMerchantBalanceSubActionTab[] => {
    const tabs = [];
    if (showLien && availableBalance > 0 && isAllowed(userAccess, ['lien.create'])) {
      tabs.push({
        actions: () => handleLienOption('place', '', ''),
        label: 'Place Lien on Account',
        classNames: ''
      });
    }
    if (isAllowed(userAccess, ['merchant_account_statement.process']) && merchantBalance?.data && merchantBalance?.data.length > 0) {
      tabs.push({
        actions: () => setShowGenerateBalanceModal(true),
        label: 'Generate Statement',
        classNames: ''
      });
    }

    return tabs;
  };
  const subActionTabs = displaySubActionTabs();
  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email as string} visible={showLargeExportModal} />
      <div className="nav-content active" id="merchantWalletTab">
        <div className="element-box">
          <section>
            <article>
              <h5 className="form-header">Balances</h5>
              <div className="form-desc no-underline mb-2">
                See the
                {` merchant's `}
                balances as well as debit and credit entries for each currency.
              </div>
            </article>
          </section>

          <section className="element-wrapper pb-0 pt-3">
            <div className="element-header justify-content-between align-items-center d-flex pb-0">
              <h6 className="mb-0">{currency} Balance</h6>
              <div>
                <CurrencyPicker
                  options={Object.keys(customTabOrder(balances) || {})}
                  activeCurrency={currency}
                  onChange={value => searchQuery.setQuery({ currency: value, page: '1' })}
                  className="mt-1 mb-3"
                  id="merchant-balances__currency-switch"
                />
              </div>
            </div>
          </section>
          <section className="element-wrapper mt-4">
            <div
              className={
                'wallet-box ' + ((lienSummaryData?.data.count > 0 && showLien) || subActionTabs.length > 0 ? 'lien-available' : 'no-lien')
              }
            >
              <div className="wallet-details">
                {balanceIsLoading ? (
                  <div className="wallet-details --loading">
                    <LoadingPlaceholder content={2} section={1} rows={2} type="text" />
                    <LoadingPlaceholder content={2} section={1} rows={2} type="text" />
                  </div>
                ) : (
                  swapArrayPositions(Object.keys(balances[currency as keyof typeof balances] || {}), 0, 1).map(balance => {
                    if (balance === 'ledger_balance') return null;
                    return (
                      <div className="wallet-data" key={String(balance)}>
                        <p className="wd-label">
                          {capitalizeRemovedash(balance as string)} ({currency})
                        </p>
                        {balance === 'is_default' ? (
                          <p className="wd-value">{balances[currency as keyof typeof balances][balance] === true ? 'YES' : 'NO'}</p>
                        ) : (
                          <p className="wd-value">
                            {balances[currency as keyof typeof balances][balance as keyof (typeof balances)[CurrencyType]]
                              ? formatAmount(
                                  Number(balances[currency as keyof typeof balances][balance as keyof (typeof balances)[CurrencyType]] ?? 0)
                                )
                              : '0.00'}
                          </p>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
              {subActionTabs && subActionTabs.length > 0 && (
                <div className="lien-container">
                  {subActionTabs.map((tab, index) => (
                    <span key={index} onClick={tab.actions} onKeyDown={tab.actions} tabIndex={0} role="button" className={tab.classNames}>
                      {tab.label}
                    </span>
                  ))}
                </div>
              )}
            </div>
            {!lienSummaryLoading && lienSummaryData?.data.count > 0 && showLien && (
              <div className="lien-summary">
                <span className="lien-summary-left">
                  <Icon name="warningTriangle" />
                  <p>{`A lien of ${currency} ${formatAmount(
                    lienSummaryData?.data?.totalAmount
                  )} has been placed on this merchant’s ${currency} balance.`}</p>
                </span>
                <span className="lien-summary-right">
                  <p>Lien(s)</p>
                  <span>
                    <p>{lienSummaryData?.data?.count}</p>
                  </span>
                </span>
              </div>
            )}
          </section>
        </div>
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex balances__history-tabs">
            <ul className="nav nav-tabs">
              {[...new Set(tabs)].map(tab => {
                if (!balancesData?.data[currency]?.hasOwnProperty('reserve_balance') && tab === tabType.reserve) return null;
                return (
                  <li
                    className="nav-item"
                    key={tab}
                    onClick={() => searchQuery.setQuery({ subTab: tab, page: '1' })}
                    onKeyUp={() => searchQuery.setQuery({ subTab: tab, page: '1' })}
                    role="tab"
                    tabIndex={0}
                  >
                    <button type="button" className={`nav-link ${activeTab === tab && 'active'}`}>
                      {tab}
                      {' History '}
                      {tab === tabType.balance && `(${merchantBalance?.paging?.total_items || 0})`}
                      {tab === tabType.reserve && `(${reserveBalance?.paging?.total_items || 0})`}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="element-wrapper pt-0">
          <Table
            className={switchBalanceHistory(activeTab, handleLienOption, userAccess)?.className}
            data={currentData?.data}
            renderFields
            hasPagination
            tableHeadings={tableDataKeys}
            emptyStateHeading={switchBalanceHistory(activeTab, handleLienOption, userAccess)?.emptyStateHeading || ''}
            tableWrapperClassName=""
            emptyStateMessage={switchBalanceHistory(activeTab, handleLienOption, userAccess)?.emptyStateMessage || ''}
            type={tableType[activeTab]}
            current={Number(currentPage)}
            totalItems={currentTotalItems || 0}
            pageSize={currentPageSize || 0}
            filterHasAdvancedFilter={false}
            filterExportAction={exportMerchantHistory}
            filterExportModalHeading={`Export ${activeTab} History`}
            filterExportModalDescription={`Choose how you would like to export this ${activeTab.toLowerCase()} history.`}
            filterExportName={`Export ${activeTab} History`}
            filterShowExport={filterExportPermission[activeTab] as boolean}
            hasFilter={filterExportPermission[activeTab] as boolean}
            filterKeywordPlaceholder={activeTab === 'Lien' ? 'Search by Lien ID' : ''}
            exportModalHeaderBottomBorder
            showExportModalDateRange
            exportModalScrollable
            loading={currentLoading}
            actionFn={current => searchQuery.setQuery({ page: String(current) })}
            limitAction={newLimit => searchQuery.setQuery({ limit: String(newLimit) })}
            annotation={`${activeTab}(s)`}
          >
            {switchBalanceHistory(activeTab, handleLienOption, userAccess)?.fields}
          </Table>
        </section>

        <LienModal
          lienStage={lienStage}
          setLienStage={(value: LienStageType) => setLienStage(value)}
          accountId={merchantDetails?.kora_core_engine_id}
          merchantName={merchantDetails?.name}
          amount={String(transactionDetail.amount)}
          lienReference={transactionDetail.reference}
          currency={currency as CurrencyType}
          balance={availableBalance}
        />
        <GenerateBalanceStatementModal
          isVisible={showGenerateBalanceModal}
          onClose={() => setShowGenerateBalanceModal(false)}
          currency={currency as CurrencyType}
          merchantId={id}
        />
      </div>
    </>
  );
}
