import React, { Dispatch, SetStateAction } from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import { TConversionsError } from '+types/conversions';

const settlementConfirmationUI = ({
  defaultCurrency,
  currency,
  rateMarkup,
  markupLimit
}: {
  defaultCurrency: string;
  currency: string;
  rateMarkup: string;
  markupLimit: string;
}) => (
  <div className="modal-ui">
    <div className="activate-conversions" data-testid="confirm-update-conversions">
      <div className="activate-conversions-active-currency">
        <span>
          <strong>{defaultCurrency}</strong> transactions will be settled in <strong>{currency}</strong>.
        </span>
      </div>
      <ul className="activate-conversions__changes">
        <li className="activate-conversions__changes-item">
          <span>Currency Markup</span>
          <span>{rateMarkup} %</span>
        </li>
        <li className="activate-conversions__changes-item">
          <span>Markup Limit</span>
          <span>{markupLimit} %</span>
        </li>
      </ul>
    </div>
    <div className="alert">
      <Icon name="infoRounded" fill="#FA9500" height={24} width={40} />
      <strong>
        Note: Refunds and chargebacks will be converted from the settlement currency to the recipient&apos;s currency using the exchange
        rate at the time of the refund.
      </strong>
    </div>
  </div>
);

const actionFeedback = ({ close, text }: { close: () => void; text?: string }) => (
  <div className="modal-ui conversion-success" data-testid="conversions-update-feedback">
    <Icon name="success" width={80} height={80} />
    <h4>Done!</h4>
    <p className="conversion-success__text">{text}</p>
    <button data-testid="dismiss-btn" onClick={() => close()} type="button" className="conversion-success__dismiss">
      Dismiss
    </button>
  </div>
);

export const modalContent = {
  enableConversion: {
    heading: 'Settlement Currency',
    description:
      'Please note that all NGN transactions for this merchant will be settled in the selected currency from this update onwards. The changes you have made are listed below:',
    content: settlementConfirmationUI,
    size: 'md',
    secondButtonText: 'Yes, Save',
    secondButtonColor: '#24B314'
  },

  enableConversionSuccess: {
    content: actionFeedback,
    showButtons: false,
    size: 'sm'
  },

  disableConversions: {
    heading: (currency: string) => `Disable Swap Settlement for ${currency}`,
    content: (currency: string) => (
      <span data-testid="confirm-disable-text">
        Please confirm that you want to disable swap settlements for this currency. This will reset the merchant’s {currency} settlements to
        the default currency.
      </span>
    ),
    size: 'sm',
    secondButtonColor: '#F32345',
    secondButtonText: 'Yes, Disable'
  },

  disableConversionSuccess: {
    content: actionFeedback,
    showButtons: false,
    size: 'sm'
  },

  settlementDestination: {
    heading: 'Settlement Currency',
    description: 'What currency would you like to be settled in?',
    content: (currency: string, setCurrency: Dispatch<SetStateAction<string>>) => (
      <div className="modal-ui settlement-destination">
        <label htmlFor="select-currency">Currency</label>
        <div className="select-wrapper">
          <select value={currency} onChange={e => setCurrency(e.target.value)} id="select-currency">
            <option value="NGN">NGN</option>
            <option value="USD">USD</option>
          </select>
          <Icon name="caretDown" width={30} height={30} fill="#3E4B5B" />
        </div>
      </div>
    ),
    size: 'md',
    secondButtonText: 'Continue',
    secondButtonDisable: (arg: boolean) => arg
  }
};

export const successText = ({ fromCurrency, toCurrency }: { fromCurrency: string; toCurrency: string }) =>
  `You have successfully changed the settlement currency to ${toCurrency} for ${fromCurrency} transactions and set the markup limit.`;

export const errorText = (currency: string) => `You have successfully disabled ${currency} Swap Settlement for this merchant.`;

export const formatError = (e: TConversionsError) => {
  const error = e?.response?.data;
  return (
    Object.values(error?.data || {})[0]?.customErrorMessage ||
    error?.message ||
    `There has been an error with this settlement conversion process`
  );
};
