import React, { useEffect, useState } from 'react';

import Icon from '+shared/Icons';
import { CurrencyType, LienRadioLabelType, LienStageType } from '+types';
import { formatAmount } from '+utils';

import './index.scss';

const messages = {
  failure: 'Failure to provide adequate licensing documents',
  unsupported: 'We do not currently support this industry',
  fraud: 'We found a record(s) of fraudulent history',
  custom: 'Custom reason'
};

const releaseMessages = {
  all: 'All necessary documents have been submitted',
  custom: 'Custom reason'
};

const radioLabel = {
  'confirm-lien': {
    ...messages
  },
  release: {
    ...releaseMessages
  },
  debit: {
    ...messages
  }
} satisfies Omit<Record<LienStageType, Partial<Record<LienRadioLabelType, string>>>, '' | 'place'>;

const radioInput = {
  'confirm-lien': ['failure', 'unsupported', 'fraud', 'custom'],
  release: ['all', 'custom'],
  debit: ['failure', 'unsupported', 'fraud', 'custom']
};

const LienForm = ({
  type,
  setReason,
  currency,
  amount
}: {
  type: LienStageType;
  setReason: (value: string) => void;
  currency: CurrencyType;
  amount: number;
}) => {
  const defaultSelectedReason = ['confirm-lien', 'debit'].includes(type) ? 'failure' : 'all';
  const [selected, setSelected] = useState<string>(defaultSelectedReason);
  const [custom, setCustom] = useState<string>('');

  useEffect(() => {
    setReason(
      selected === 'custom'
        ? custom
        : `${
            (radioLabel[type as keyof typeof radioLabel] as Partial<Record<LienRadioLabelType, string>>)[
              selected as Exclude<LienRadioLabelType, 'all'>
            ]
          }`
    );
  }, [selected, custom]);

  const reasonContent = () => {
    return (
      <div className="reason-radio_container">
        {radioInput[type as keyof typeof radioInput].map(item => {
          const selectedMessage = `${
            (radioLabel[type as keyof typeof radioLabel] as Partial<Record<LienRadioLabelType, string>>)[
              item as Exclude<LienRadioLabelType, 'all'>
            ]
          }`;
          return (
            <label key={item}>
              <input checked={item === selected} type="radio" onChange={() => setSelected(item)} />
              {selectedMessage}
            </label>
          );
        })}
      </div>
    );
  };
  const content = {
    release: (
      <>
        <p className="description">Kindly confirm that you want to release a lien on merchant’s Naira account.</p>
        <span className="lien-banner">
          <Icon name="envelope" />
          <p>{`The minimum balance requirement of ${currency} ${formatAmount(amount)} will be released.`}</p>
        </span>
        <p className="reason-header">Select a reason for releasing lien?</p>
        <span>{reasonContent()}</span>
        <label htmlFor="reason-custom" className="reason-header">
          Custom Reason
        </label>
        <div className="reason-custom">
          <textarea
            id="reason-custom"
            className="form-control"
            placeholder="Enter custom reason"
            rows={3}
            onChange={e => setCustom(e.target.value)}
            disabled={selected !== 'custom'}
          />
        </div>
      </>
    ),
    debit: (
      <>
        <p className="description">Kindly confirm that you want to keep this liened amount with Kora.</p>
        <span className="lien-banner">
          <Icon name="envelope" />
          <p>{`This liened amount of ${currency} ${formatAmount(amount)}  will be forfeited by the merchant.`}</p>
        </span>
        <p className="reason-header">Select a reason for debiting lien?</p>
        <span>{reasonContent()}</span>
        <label htmlFor="reason-custom" className="reason-header">
          Custom Reason
        </label>
        <div className="reason-custom">
          <textarea
            id="reason-custom"
            className="form-control"
            placeholder="Enter custom reason"
            rows={3}
            onChange={e => setCustom(e.target.value)}
            disabled={selected !== 'custom'}
          />
        </div>
      </>
    ),
    'confirm-lien': (
      <>
        <p className="description">Kindly confirm that you want to create a lien on this transaction</p>
        <span className="lien-banner">
          <Icon name="envelope" />
          <p>
            {`This transaction amount of ${currency} ${formatAmount(amount)} will be held and cannot be withdrawn until lien is lifted.`}
          </p>
        </span>
        <p className="reason-header">Select a reason for creating lien?</p>
        <span>{reasonContent()}</span>
        <label className="reason-header" htmlFor="reason-custom">
          Custom Reason
        </label>
        <div className="reason-custom">
          <textarea
            id="reason-custom"
            className="form-control"
            placeholder="Enter custom reason"
            rows={3}
            onChange={e => setCustom(e.target.value)}
            disabled={selected !== 'custom'}
          />
        </div>
      </>
    )
  };

  return <div className="lien-form-container">{content[type as keyof typeof content]}</div>;
};

export default LienForm;
