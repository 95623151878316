import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Table from '+containers/Dashboard/Shared/Table';
import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { LienDataType, LienStatusType } from '+types';
import { capitalizeRemovedash, formatAmount, getDate, getTime, switchStatus } from '+utils';

import { generateLienDetails, generateSummaryFrom } from '../helpers/lienDetailsHelper';

const api = new APIRequest();
const statusDetails = {
  released: {
    text: 'Lien released',
    statusBg: '#E4FFF1',
    statusColor: '#24B314'
  },
  withheld: {
    text: 'Lien debited',
    statusBg: '#EBEDFF',
    statusColor: '#6474FF'
  },
  active: {
    text: 'Lien placed',
    statusBg: '#FFD2DA',
    statusColor: ' #F32345'
  }
} satisfies Record<LienStatusType, { text: string; statusBg: string; statusColor: string }>;

const LienDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { feedbackInit } = useFeedbackHandler();

  const { data, refetch, isLoading } = useQuery(['LIEN_DETAILS', id], () => api.getLienDetails({ reference: id }), {
    refetchOnWindowFocus: 'always',
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this lien details `,
        type: 'danger',
        action: {
          action: () => refetch(),
          name: 'Try again'
        }
      });
    }
  });
  const lienDetailsData = data?.data as LienDataType;

  const switchLienHistory = () => {
    return {
      className: '--lien-events-history',
      emptyStateHeading: 'No entries yet',
      emptyStateMessage: 'There are no results yet.',
      fields: (each: LienDataType['event_history'][0]) => ({
        data: {
          'Date/Time': (
            <>
              <span>{getDate(each.date)}</span>
              <span className="annotation" style={{ marginLeft: '5px' }}>
                {getTime(each.date)}
              </span>
            </>
          ),
          'Transaction Reference': <span>{each.source_reference?.toUpperCase()}</span>,
          Reason: each.reason,
          Status: (
            <>
              <span className={`status-pill smaller ${switchStatus(`lien_${each.status}`)}`} />
              <span>{statusDetails[each.status]?.text}</span>
            </>
          ),

          'Initiated by': <span className="lien-initiator">{each?.admin_name ? capitalizeRemovedash(each?.admin_name) : 'N/A'}</span>,

          Amount: <span>{formatAmount(Number(lienDetailsData?.amount))}</span>
        }
      })
    };
  };

  const tableDataKeys = Object.keys(switchLienHistory().fields({} as LienDataType['event_history'][0]).data);

  return (
    <TransactionDetails>
      <TransactionDetails.Header
        heading={formatAmount(lienDetailsData?.amount)}
        currency={lienDetailsData?.currency}
        summaries={generateSummaryFrom(lienDetailsData)}
        isLoading={isLoading}
        statusLabels={[
          {
            status: statusDetails[lienDetailsData?.status]?.text,
            statusBg: statusDetails[lienDetailsData?.status]?.statusBg,
            statusColor: statusDetails[lienDetailsData?.status]?.statusColor
          }
        ]}
      />
      <TransactionDetails.Section heading="Lien Details" summaries={generateLienDetails(lienDetailsData)} isLoading={isLoading} />
      <section className="trxn-layout-section">
        <div className="section-title">
          <div className="section-title__left">
            <p>Event</p>
          </div>
        </div>
        <div className="lien-events pt-4 fade-in">
          <Table
            className={switchLienHistory()?.className}
            data={lienDetailsData?.event_history || []}
            renderFields
            hasPagination={false}
            tableHeadings={tableDataKeys}
            emptyStateHeading={switchLienHistory()?.emptyStateHeading || ''}
            tableWrapperClassName=""
            emptyStateMessage={switchLienHistory()?.emptyStateMessage || ''}
            type={'lien-event-history'}
            filterHasAdvancedFilter={false}
            hasFilter={false}
            loading={isLoading}
            annotation={'Event History'}
          >
            {switchLienHistory()?.fields}
          </Table>
        </div>
      </section>
    </TransactionDetails>
  );
};

export default LienDetails;
